import React, { useEffect } from 'react';
import Particles from 'particlesjs';


const ParticlesBackground = () => {
  useEffect(() => {
    Particles.init({
      selector: '.particles-background',
      maxParticles: 518,
      color: '#03d189',
      size: {
      value: 15,
      random: true,
      }
  });
  }, []);

  const canvasStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    pointerEvents: 'none'
  };

  return <canvas className="particles-background" style={canvasStyle}></canvas>;
};

export default ParticlesBackground;