import React from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';
import withParticles from './withParticles';

function Writing() {
  return (
    <div className="App">
      <header className="App-header">
        <Container className='Box'>
          <div align="center">
            <br /><br />
            <p><b><u>Thought Leadership and Media Coverage:</u></b></p>
          </div>
          <div align="center">
            <br />
            <p><b><u>2024:</u></b></p>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://youtu.be/5jOGpiKM1Dg?feature=shared" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>IPFS Camp 2024 || Intro to Nova Energy</b>
                  </a>
                </li>
            </ul>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://medium.com/nova-energy/the-urgency-of-sustainable-digital-practices-how-nova-energy-is-leading-the-charge-330c6e442597" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Nova Energy || The Urgency of Sustainable Digital Practices</b>
                  </a>
                </li>
            </ul>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://protocol.ai/blog/meet-nova-energy-building-a-sustainable-future-in-web3/" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Protocol Labs || Meet Nova Energy</b>
                  </a>
                </li>
            </ul>
            <p><b><u>2023:</u></b></p>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://www.celeste.fr/espace-presse/twin-quasar-et-celeste-creent-une-offre-de-green-data-hosting/" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Celeste || Twin Quasar Green Hosting</b>
                  </a>
                </li>
            </ul>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://medium.com/@filecoingreen/composing-the-symphony-of-a-carbon-negative-digital-future-the-green-scores-methodology-f5aeaa20797e" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Filecoin Green || Green Scores Methodology</b>
                  </a>
                </li>
            </ul>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://medium.com/@filecoingreen/embarking-on-the-path-of-sustainability-your-guide-to-the-energy-validation-process-evp-2c11b989a96a" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Filecoin Green || Energy Validation Process</b>
                  </a>
                </li>
              </ul>
            
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://www.coindesk.com/consensus-magazine/2023/08/30/save-a-tree-use-web3/" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Coindesk || Save a Tree, Use Web3</b>
                  </a>
                </li>
              </ul>
              <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://www.youtube.com/live/1nuMk72bytg?feature=shared&t=352" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Filecoin Green || SBS Earth</b>
                  </a>
                </li>
              </ul>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://www.decential.io/articles/filecoin-green-is-helping-blockchain-run-on-sustainable-energy" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>DECENTIAL || Filecoin Green Sustainable Energy</b>
                  </a>
                </li>
              </ul>
            <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://medium.com/@filecoingreen/a-green-new-web-how-filecoin-green-is-paving-the-way-to-a-carbon-negative-digital-future-84b1b817eb95" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>Filecoin Green || A Green New Web</b>
                  </a>
                </li>
              </ul>
              <ul className="credits-center"> 
                <li className="credits-center">
                  <a href="https://www.weforum.org/whitepapers/blockchain-for-scaling-climate-action" target="_blank" rel="noopener noreferrer" className="App-link">
                    <b>WEF || Blockchain for Scaling Climate Action</b>
                  </a>
                </li>
              </ul>
            <p><b><u>2022:</u></b></p>
            <ul className="credits-center"> 
            <li className="credits-center">
                <a href="https://blog.ipfs.tech/2022-11-10-guardian-ipfs-and-hedera/" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>IPFS Blog || Guardian - IPFS & Hedera</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://www.coindesk.com/tv/money-reimagined/money-reimagined-sept-16-2022-20220916/" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>Coindesk || Tech Solutions to Climate Change Concerns</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://filecoin.io/blog/posts/how-to-become-a-green-storage-provider/" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>Filecoin Green || How to Become a Green SP</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://filecoin-green.gitbook.io/filecoin-green-documentation/readme-1" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>Filecoin Green || Green Guidance Documentation</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://www.youtube.com/watch?v=_jZDJp0KVTc&t=14s" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>Filecoin Green || Environmentally Beneficial Network</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://youtu.be/vjXyKn9zAzk?t=8585" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>NEARCON 2022 || The Refi Movement</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://www.youtube.com/watch?v=ipd5vGAjo8o" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>EthBarcelona || Decarbonize Crypto & the World</b>
                </a>
              </li>
              <li className="credits-center">
                <a href="https://beincrypto.com/corporations-claiming-sustainability-need-to-prove-it-heres-how/" target="_blank" rel="noopener noreferrer" className="App-link">
                  <b>Be[In] Crypto || Corps Need to Prove Enviro Claims</b>
                </a>
              </li>
            </ul>
            <p><b><u>2021:</u></b></p>
              <ul class="list-inline">
                <li class="credits-center"><a href="https://www.coindesk.com/rocky-mountain-institute-proposes-protocol-to-track-climate-emissions" target="_blank" rel="noopener noreferrer" className="App-link"><b>Coindesk || RMI Proposes Protocol to Track Emissions</b></a></li>
                
                <li class="credits-center"><a href="https://rmi.org/for-the-eus-groundbreaking-climate-law-data-is-key/" target="_blank" rel="noopener noreferrer" className="App-link"><b>RMI || For EU’s Groundbreaking Climate Law, Data is Key</b></a></li>
                
                <li class="credits-center"><a href="https://rmi.org/why-we-need-to-standardize-emissions-tracing-to-get-to-net-zero/" target="_blank" rel="noopener noreferrer" className="App-link"><b>RMI || Why We Need Standard Emissions Tracing</b></a></li>
                
                <li class="credits-center"><a href="https://marcjohnson.info/CCA" target="_blank" rel="noopener noreferrer" className="App-link"><b>Crypto Climate Accord || Carbon Accounting Guidance</b></a></li>
                
                <li class="credits-center"><a href="/RMI" target="_blank" rel="noopener noreferrer" className="App-link"><b>RMI || Principles for Blockchain-based Reporting</b></a></li>
              </ul>
              
              <p><b><u>2020:</u></b></p>
              <ul class="list-inline">
                <li class="credits-center"><a href="https://open.spotify.com/episode/1pMZodYhhQYm5B2tPqxln4?si=wi61zeYYSoqE5EpJY34HPA" target="_blank" rel="noopener noreferrer" className="App-link"><b>PODCAST: Blockchain Rock || Season 3 Ep. 1</b></a></li>
                
                <li class="credits-center"><a href="https://www.springer.com/gp/book/9783030522742" target="_blank" rel="noopener noreferrer" className="App-link"><b>Springer Publishing || Theories of Change: Ch. 20</b></a></li>
        
                <li class="credits-center"><a href="https://www.second-breakfast.co/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Personal Blog || Second Breakfast</b></a></li>
              </ul>
              
              <p><b><u>2019:</u></b></p>
              <ul class="list-inline">
                <li class="credits-center"><a href="https://www.cointrust.com/market-news/blockchain-based-fuel-tracking-solution-bunkertrace-goes-commercial" target="_blank" rel="noopener noreferrer" className="App-link"><b>CoinTrust || BunkerTrace Goes Commercial</b></a></li>
              
                <li class="credits-center"><a href="https://www.bunkerspot.com/europe/49083-europe-milestone-for-bunkertrace-marine-fuels-tracking-solution" target="_blank" rel="noopener noreferrer" className="App-link"><b>BunkerSpot || BunkerTrace Successfully Completes Pilot</b></a></li>
                
                <li class="credits-center"><a href="https://www.ledgerinsights.com/bunkertrace-dna-blockchain-maritime-fuel-tracking/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Ledger Insights || BunkerTrace Maritime Fuel Tracking</b></a></li>
                
                <li class="credits-center"><a href="https://decrypt.co/10718/bunkertrace-dna-blockchain-trace-maritime-bunker-fuel" target="_blank" rel="noopener noreferrer" className="App-link"><b>Decrypt || DNA and Blockchain to Trace Maritime Fuel</b></a></li>
                
                <li class="credits-center"><a href="https://blockchainmagazine.net/bunkertrace-a-blockchain-a%E2%80%8Bnd-dna-based-fuel-tracking-solution/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Blockchain Magazine || Fuel Tracking Solution</b></a></li>
              </ul>
              
              <p><b><u>2018:</u></b></p>
                <ul className="list-inline">

                  <li className="credits-center"><a href="https://marcjohnson.info/unfccc_dlt.html" target="_blank" rel="noopener noreferrer" className="App-link"><b>UNFCCC || Assessing the DLT Ecosystem</b></a></li>
                  
                  <li className="credits-center"><a href="/Carbon" target="_blank" rel="noopener noreferrer" className="App-link"><b>Columbia University || Linking Carbon Markets</b></a></li>
                  
                  <li className="credits-center"><a href="https://research.wu.ac.at/ws/portalfiles/portal/19004580/SustainableDevelopment+Report_BlockchainWeb3_SDGs.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>Vienna University (WU) || Blockchain, Web3 & the SDGs</b></a></li>
                  
                  <li className="credits-center"><a href="https://irena.org/-/media/Files/IRENA/Agency/Publication/2019/Feb/IRENA_Landscape_Blockchain_2019.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>IRENA || Blockchain Innovation Landscape Brief</b></a></li>

                  <li className="credits-center"><a href="https://www.giz.de/en/downloads/giz2019_en_blockchain_world_without_middleman.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>GIZ || Blockchain: A World Without Middlemen?</b></a></li>
                </ul>
                
                <p><b><u>2017:</u></b></p>
                <ul className="list-inline">
                <li className="credits-center"><a href="https://marcjohnson.info/E_&_SD.html" target="_blank" rel="noopener noreferrer" className="App-link"><b>Columbia Univ || Energy & Sustainable Development</b></a></li>
                  
                <li className="credits-center"><a href="https://marcjohnson.info/NYC_2018.html" target="_blank" rel="noopener noreferrer" className="App-link"><b>Columbia Univ || NY State's Installed Capacity</b></a></li>
                </ul>
              
              <p><b><u>2016:</u></b></p>
              <ul class="list-inline">
              <li class="credits-center"><a href="https://medium.com/@marcjohnson/the-trillion-dollar-renewable-energy-investment-deficit-no-one-is-talking-about-751c79d5521c" target="_blank" rel="noopener noreferrer" className="App-link"><b>The Trillion Dollar Renewable Energy Deficit</b></a></li>

              <li class="credits-center"><a href="https://medium.com/@marcjohnson/environmental-stewardship-to-propel-global-growth-4e1cf06d9b14" target="_blank" rel="noopener noreferrer" className="App-link"><b>Environmental Stewardship to Propel Global Growth</b></a></li>
              </ul>
              </div>
            <div className="credits-center">
            <Link to="/"><button className="button">Home</button></Link>
           </div> 
          </Container>
        </header>
        </div>
        );
    }
    
    export default withParticles(Writing);
