import React from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';

function Solidity() {

const pdfUrl = process.env.PUBLIC_URL + "assets/MARC-JOHNSON-CHAINSHOT.pdf";

return (
    <div className="App">
    <header className="App-header">
        <Container className='Box'>      
            <h3 className='highlight'>Chainshot Solidity Graduate Certification</h3>
            <iframe src={pdfUrl} width="70%" height="600px" title='mj_chainshot'></iframe>
            <div className="credits">
                <Link to="/"><button className="button">Home</button></Link>
            </div>   
        </Container>
    </header>
    </div>
);
}
export default Solidity;