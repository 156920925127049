import React from 'react';
import ParticlesBackground from './ParticlesBackground';

const withParticles = (Component) => {
  const WithParticles = (props) => (
    <div>
      <ParticlesBackground />
      <Component {...props} />
    </div>
  );

  return WithParticles;
};

export default withParticles;