import React from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import kramerImage from './assets/Cosmo_Kramer.jpeg';
import { Link } from 'react-router-dom'; 

const NotFound = () => {
  return (
    <header className="App-header">
        <Container className='Box'>
    <div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      <div style={{ textAlign: 'center' }}>
        <img src={kramerImage} alt="Cosmo Kramer" style={{ maxWidth: '80%' }} />
        <p className='credits-center'><b>Looks like you found the end of the internet.</b></p>
      </div>
    </div>
    <Link to="/">
            <button className="button">Home</button>
    </Link>
    </div>
    </Container>
    </header>
  );
}

export default NotFound;


