import React, { useState} from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import "material-icons";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import StormIcon from '@mui/icons-material/Storm';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';


function MoreInfoButton({ visible }) {
  const [open, setOpen] = useState(false);


  return visible && (
    <div>
      <button className="button"
        onClick={() => setOpen(!open)}
        aria-controls="more-info"
        aria-expanded={open}
      >
        Even More Info
      </button>
      <Collapse in={open}>
        <div> <br></br>
          <p><b>Choose your adventure:</b></p>
          <div className="morpheus">
            <Link to="/Info">
              <button className="button red"><span data-title="Sure?">Red Pill</span></button>
            </Link>
            
            <Link to="/MJ">
              <button className="button blue">Blue Pill</button>
            </Link>
          </div>
          <div className='credits-center skinny'>
                  <p><small><i>"To talk about energy and the economy is a tautology: every economic activity is fundamentally nothing but a conversion of one kind of energy to another, and monies are just a convenient (and often rather unrepresentative) proxy for valuing the energy flows."</i></small></p>
                  <p><small> - Vaclav Smil, <a href="https://www.amazon.com/Energy-Civilization-History-MIT-Press/dp/0262035774" target="_blank" rel="noopener noreferrer" className="App-link"><i>Energy and Civilization</i></a></small></p>
                </div>
                <p>You can find my old site here: <a href="https://marcjohnson.info" target="_blank" rel="noopener noreferrer" className="App-link"><b>old.marcjohnson.info</b></a></p>
              <div id="private-info">
                <ul className="list-inline">
                  <li className="list-inline-item">
                      <a href="https://www.marcjohnson.info/mj-pgpkey.txt" target="_blank" rel="noopener noreferrer" title="PGP Public Key" className="App-link"><VpnKeyIcon /></a>
                  </li>
                  <li className="list-inline-item">
                      <a href="http://etherscan.io/enslookup-search?search=marcjohnson.eth" target="_blank" rel="noopener noreferrer" title="ENS" className="App-link"><FingerprintIcon /></a>
                  </li>
                  <li className="list-inline-item">
                      <a href="https://github.com/mjohnson518" target="_blank" rel="noopener noreferrer" title="github" className="App-link"><GitHubIcon /></a>
                  </li>
                  <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/marc--johnson/" target="_blank" rel="noopener noreferrer" title="linkedin" className="App-link"><LinkedInIcon /></a>
                  </li>
                  <li className="list-inline-item">
                      <a href="https://twitter.com/marcjohnson518" target="_blank" rel="noopener noreferrer" title="twitter" className="App-link"><TwitterIcon /></a>
                  </li>
                  <li className="list-inline-item">
                      <a href="https://bsky.app/profile/marcjohnson.info" target="_blank" rel="noopener noreferrer" title="bluesky" className="App-link"><StormIcon /></a>
                  </li>
                </ul>
              </div>
            <Link to="/">
              <button className="button">Home</button>
            </Link>
        </div>
      </Collapse>
    </div>
  );
}

function About() {
  const [open, setOpen] = useState(false);

  const codeSnippet = 
`if regen:
    wagmi
else:
    ngmi`;

  return (
    <div className="App">
      <header className="App-header">
        <Container className='Box'>
          <div>
            <p>Olá amigos, I'm Marc <span role="img" aria-labelledby='wave'>👋</span></p>
            <p>I currently call <span role="img" aria-labelledby='PTflag'>🇵🇹</span> home, I enjoy working on challenges related to the <a href="https://www.danielyergin.com/books/thenewmap" target="_blank" rel="noopener noreferrer" className='highlight highlight-green App-link'><b>Global Energy Transition</b></a>, and my favorite band is the <a href="https://open.spotify.com/album/2Y9IRtehByVkegoD7TcLfi?si=CZA9VctiTZ67MfHm9MrK6A" target="_blank" rel="noopener noreferrer" className='highlight highlight-green App-link'><b>Red Hot Chili Peppers</b></a>.</p><p>I also really like books about <a href="https://en.wikipedia.org/wiki/The_Brain_that_Changes_Itself" target="_blank" rel="noopener noreferrer" className='highlight highlight-green App-link'><b>Neuroplasticity</b></a>, the occasional glass of <a href="https://wome.pt/" target="_blank" rel="noopener noreferrer" className='highlight highlight-green App-link'><b>Portuguese Wine</b></a>, and all things <a href="https://youtu.be/l44z35vabvA" target="_blank" rel="noopener noreferrer" className='highlight highlight-green App-link'><b>Web 3</b></a>.</p>
            <p>I'm the Co-founder/CEO of <a href="https://novaenergy.ai/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Nova Energy</b></a>. We build solutions to monitor and mitigate the environmental impact of compute environments.</p>
            <p>Previously, I was an Environmental Solutions Architect for <a href="https://green.filecoin.io/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Filecoin Green</b></a>, a <a href="https://protocol.ai/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Protocol Labs</b></a> initiative to make environmental accounting a web3 superpower.</p>
            <p>I also run a web3 infrastructure company called <a href="https://c12.space" target="_blank" rel="noopener noreferrer" className="App-link"><b>Carbon Twelve</b></a>.</p>
          </div>

          <button className="button"
            onClick={() => setOpen(!open)}
            aria-controls="more-info"
            aria-expanded={open}
          >
            More Info
          </button>
          <Collapse in={open}>
            <div>
              <h4>Thought Leadership and Media Coverage:</h4>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href="/Work" className="App-link"><b>Writings / News</b></a>
                  </li>
                </ul>  

              <h4>Old Side Projects:</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="https://unios.world/" target="_blank" rel="noopener noreferrer" className="App-link"><b>Unios World</b></a>
                  <b> || </b>
                  <a href="https://github.com/mjohnson518/Carbon12" target="_blank" rel="noopener noreferrer" className="App-link"><b>Carbon12</b></a>
                  <b> || </b>
                  <a href="https://ndcportal.app/" target="_blank" rel="noopener noreferrer" className="App-link"><b>NDC Portal</b></a>
                </li>
              </ul>

              <h4>Education</h4>
                <p className="App-link"><b>
                  Columbia University
                  <br />
                  MSc Sustainability Management
                  </b></p>

              <h4>Additional Credentials:</h4>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="/assets/MARC-JOHNSON-CHAINSHOT.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>Chainshot Certified Ethereum Developer</b></a></li> <br></br>
                <li className="list-inline-item"><a href="/assets/Certificate_of_Completion_MJ.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>GHG Protocol Mitigation Goal Standard</b></a></li>  <br></br>
                <li className="list-inline-item"><a href="/assets/Scope 3 Standard_Certificate_MJ.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>GHG Protocol Scope 3 Standard</b></a></li>  <br></br>
                <li className="list-inline-item"><a href="/assets/mj_rust.pdf" target="_blank" rel="noopener noreferrer" className="App-link"><b>Rust Bootcamp Certificate</b></a></li> <br></br>
              </ul>

            
            <h4>Mantra:</h4>
              <SyntaxHighlighter language="javascript" style={materialDark} className="code-block">
                {codeSnippet}
              </SyntaxHighlighter>
            
          </div>
         </Collapse>
          <MoreInfoButton visible={open} />
      </Container>
    </header>
  </div>
);
}
export default About;
