import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './App.css';
import withParticles from './withParticles';

const ResumeTimeline = () => {
  return (
    <VerticalTimeline>
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)',  borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Co-Founder / CEO</h3>
        <h4 className="vertical-timeline-element-subtitle"><a href="https://novaenergy.ai" target="_blank" rel="noopener noreferrer" className="App-link"><b>Nova Energy</b></a></h4>
        <h5 className="vertical-timeline-element-subtitle">March 2024 - Present</h5>
        <p className='timeline-bullet-points'>
          Through monitoring and mitigating the environmental impacts of Web3 node operators, AI models, and general data center operations, Nova Energy offers an energy auditing process to catalyze a transition towards a greener digital world.
        </p>
      </VerticalTimelineElement>
    
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)',  borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Founder</h3>
        <h4 className="vertical-timeline-element-subtitle"><a href="https://c12.space" target="_blank" rel="noopener noreferrer" className="App-link"><b>Carbon Twelve</b></a></h4>
        <h5 className="vertical-timeline-element-subtitle">November 2023 - Present</h5>
        <p className='timeline-bullet-points'>
          Harnessing the power of the <a href="https://internetcomputer.org/" className="App-link" target="_blank" rel="noopener noreferrer">Internet Computer</a> and <a href="https://filecoin.io" className="App-link" target="_blank" rel="noopener noreferrer">Filecoin</a> to forge a more sustainable future.
        </p>
      </VerticalTimelineElement>
        
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)',  borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Environmental Solutions Architect</h3>
        <h4 className="vertical-timeline-element-subtitle">Protocol Labs - Filecoin Green</h4>
        <h5 className="vertical-timeline-element-subtitle">March 2022 - March 2024</h5>
        <p className='timeline-bullet-points'>
          Designed, implemented, and maintained the Storage Providers Green Guidance, co-developed various solutions and processes to move the Filecoin network to a decarbonized future.
        </p>
        <p className='timeline-bullet-points'> 
          Led the creation and implementation of the Energy Validation Process and Green Scores to assist Storage Providers in decarbonizing operations based on verifiable claims.
        </p>
      </VerticalTimelineElement>

      {/* Other Work Experiences */}
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)', borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Senior Associate</h3>
        <h4 className="vertical-timeline-element-subtitle">Rocky Mountain Institute (RMI)</h4>
        <h5 className="vertical-timeline-element-subtitle">Feb 2021 - March 2022</h5>
        <p className='timeline-bullet-points'>
          Contributed to the success of the Climate Intelligence Program as the leader of digitization efforts for Horizon
          Zero, key manager of the COMET initiative, and project lead for the Crypto Climate Accord.
        </p>
        <p className='timeline-bullet-points'> 
            Leveraged domain-expertise in data analysis and product development to craft actionable insights from emissions data.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)', borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Executive Consultant</h3>
        <h4 className="vertical-timeline-element-subtitle">Hassan’s International Law Firm </h4>
        <h5 className="vertical-timeline-element-subtitle">April 2020-Jan 2021</h5>
        <p className='timeline-bullet-points'>
            Served as key advisor for the Government of Gibraltar on the Implementation of objectives and financing mechanisms under Article 6 of Paris Agreement.
        </p>
        <p className='timeline-bullet-points'> 
        Assisted in developing regulatory framework addressing legal, commercial, and technical structure for the Voluntary Trading of Green Instruments.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)', borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Chief Executive Officer</h3>
        <h4 className="vertical-timeline-element-subtitle">BunkerTrace</h4>
        <h5 className="vertical-timeline-element-subtitle">Sept 2019-Feb 2020</h5>
        <p className='timeline-bullet-points'>
            Recruited as a leader of BLOC to lead pioneering joint-venture that combined elements of blockchain-based reporting platform with synthetic-DNA physical tracer that enabled full value-chain traceability for maritime sector.
        </p>
        <p className='timeline-bullet-points'> 
            Conceptualized, developed and executed long-term strategies, technical solutions, business models & scaling processes.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: 'rgb(0, 0, 0)', borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">Chief Sustainability Officer</h3>
        <h4 className="vertical-timeline-element-subtitle">Blockchain Labs for Open Collaboration (BLOC)</h4>
        <h5 className="vertical-timeline-element-subtitle">Jan 2019-Feb 2020</h5>
        <p className='timeline-bullet-points'>
            Spearheaded multiple global projects and served as Subject Matter Expert for sustainability initiatives within the maritime, energy, and water industries. 
        </p>
        <p className='timeline-bullet-points'> 
            Led product development and implemented strategies that successfully integrated solutions, SaaS business models, and improved processes.
        </p>
      </VerticalTimelineElement>
      
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: '#fff',  borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">MSc in Sustainability Management</h3>
        <h4 className="vertical-timeline-element-subtitle">Columbia University</h4>
        <h5 className="vertical-timeline-element-subtitle">The Earth Institute</h5>
        <h6 className="vertical-timeline-element-subtitle">Graduated May 2019</h6>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: '#fff',  borderRadius: '50%' }}
        contentStyle={{ background: 'black', color: 'white' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
      >
        <h3 className="vertical-timeline-element-title">BBA in Management, Marketing & Economics</h3>
        <h4 className="vertical-timeline-element-subtitle">University of South Carolina</h4>
        <h5 className="vertical-timeline-element-subtitle">Darla Moore School of Business</h5>
        <h6 className="vertical-timeline-element-subtitle">Graduated Dec 2012</h6>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'black', color: '#fff' }}
        contentArrowStyle={{ borderRight: '15px solid  black' }}
        iconStyle={{ background: 'linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8)', color: '#fff' }}
      >
        <h3 className="vertical-timeline-element-title">Technical Knowledge</h3>
        <ul>
          <li className='timeline-bullet-points'>Backend: SQL, PostgreSQL, IPFS, Python, Django, Node.js, Express.js</li>
          <li className='timeline-bullet-points'>Frontend: HTML, CSS, JavaScript, jQuery, Vue, React, Next.js, Bootstrap, Tailwind CSS</li>
          <li className='timeline-bullet-points'>Other: Git, Github, GCP, AWS, Netlify, Vercel, Heroku, Bloomberg Terminal, Salesforce</li>
        </ul>
      </VerticalTimelineElement>

    </VerticalTimeline>
  );
};

export default withParticles(ResumeTimeline);
