import React from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom'; 

function Carbon () {

const pdfUrl = process.env.PUBLIC_URL + "assets/Linking_Carbon_Markets_Final_Paper_mj2835.pdf";

return (
    <div className="App">
    <header className="App-header">
        <Container className='Box'>      
            <iframe src={pdfUrl} width="70%" height="600px" title='Carbon'></iframe>
            <div className="credits">
                <Link to="/"><button className="button">Home</button></Link>
            </div>   
        </Container>
    </header>
    </div>
);
}

export default Carbon;