import React from 'react';
import './App.css';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';
import ResumeTimeline from './timeline';

  function Resume() {
    return (
        <div className="App">
        <header className="App-header">
         <Container className='Box'>
         <h3>Resume</h3>       
            <Container>
                <ResumeTimeline />
            </Container>
            <h3 className='highlight'>Download: <a href="/download" className="App-link"><b>Resume</b></a></h3>
             <div className="credits">
            <Link to="/"><button className="button">Home</button></Link>
           </div>   
        </Container>
        </header>
        </div>
       );
    }
    
    export default Resume;