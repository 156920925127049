import React, { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import moment from "moment";
import "./App.css";
import { Link } from 'react-router-dom';

function LifeChart() {
  const [birthDate] = useState(moment("1990-02-09"));
  const [numWeeks] = useState(90 * 52);
  const [weeksLived] = useState(moment().diff(birthDate, "weeks"));
  const [hoveredWeek, setHoveredWeek] = useState(null);

  const boxes = [];
  for (let i = 0; i < numWeeks; i++) {
    const isLived = i < weeksLived;
    const boxColor = isLived ? "#1b77f8" : "#C5C5C5";
    if (i === weeksLived) {
      boxes.push(
        <div className="weekBox"
          key={i}
          title={`Week ${i + 1}`}
          onMouseEnter={() => setHoveredWeek(i + 1)}
          onMouseLeave={() => setHoveredWeek(null)}
        >
         <span>{hoveredWeek === i + 1 ? hoveredWeek : null}</span>
        </div>
      );
    } else {
      boxes.push(
        <div
          key={i}
          title={`Week ${i + 1}`}
          onMouseEnter={() => setHoveredWeek(i + 1)}
          onMouseLeave={() => setHoveredWeek(null)}
          
          style={{
            width: "14px",
            height: "14px",
            backgroundColor: boxColor,
            margin: "1px"
          }}
        />
      );
    }
  }

  const rows = [];
  for (let i = 0; i < numWeeks / 52; i++) {
    const rowBoxes = boxes.slice(i * 52, (i + 1) * 52);
  
    rows.push(
      <div key={i} style={{ display: "flex" }}>
        {rowBoxes}
      </div>
    );
  }

  const totalBoxes = boxes.length;
  // const lastBox = boxes[totalBoxes - 1];

  const progress = ((weeksLived + 1) / numWeeks) * 100;
  const progressText = `${progress.toFixed(2)}%`;

  return (
    <div className="App">
      <header className="App-header">
        <Container className="Box">
          <div>My Life in Weeks</div>
          <div className="progress-container">
            <progress value={progress} max="100"></progress>
            <span className="progress-text">{progressText}</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
          </div>
            {rows}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Total Boxes: {totalBoxes}</div>
              <div>90 Years</div>
            </div>
            <div className="credits">
                <Link to="/Memories"><button className="button" type="submit" onSubmit={"/Memories"}>Want Even More?</button></Link> 
              </div>
          </div>
        </Container>
            <div className="credits">
                    <p>This page is inspired by two resources:</p>
                        <ul>
                        <li><a className="App-link" href="http://waitbutwhy.com/2014/05/life-weeks.html" target="_blank" rel="noopener noreferrer">Your Life in Weeks</a>, a post by Tim Urban, from <a className="App-link" href="http://waitbutwhy.com/">Wait But Why</a>.</li>
                        <li><a className="App-link" href="https://us.macmillan.com/books/9780374159122" target="_blank" rel="noopener noreferrer">Four Thousand Weeks: Time Management for Mortals</a>, a book by <a className="App-link" href="https://www.oliverburkeman.com/books">Oliver Burkeman</a>.</li>
                        </ul>
                    <p>Code adopted from <a className="App-link" href="https://github.com/bryanbraun/your-life" target="_blank" rel="noopener noreferrer">Bryan Braun</a>.</p>

            </div>
   </header>
    </div>
  );
}

export default LifeChart;