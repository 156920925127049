import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';

function PdfViewer() {
  let { pdfName } = useParams();
  const pdfUrl = process.env.PUBLIC_URL + `/assets/${pdfName}.pdf`;

  return (
    <div className="App">
      <header className="App-header">
        <Container className='Box'>      
          <iframe src={pdfUrl} width="70%" height="600px" title={pdfName}></iframe>
          <div className="credits">
            <Link to="/"><button className="button">Home</button></Link>
          </div>   
        </Container>
      </header>
    </div>
  );
}

export default PdfViewer;