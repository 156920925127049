import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Main from "./Main";
import About from "./About";
import Info from "./Info";
import Resume from "./mj_info";
import Dload from "./resume";
import Writing from "./work";
import RMI from "./RMI";
import Carbon from "./Carbon";
import Solidity from "./Chainshot";
import Rust from "./Rust";
import PdfViewer from './Pdfviewer'; 
import ImageGrid from "./Imagegrid";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import NotFound from './404';

const knownPaths = ['/', '/DOWNLOAD', '/ABOUT', '/INFO', '/MEMORIES', '/MJ', '/WORK', '/RMI', '/CARBON', '/Chainshot', '/Rust'];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  
  React.useEffect(() => {
    const currentPath = location.pathname.toUpperCase();
    if (currentPath !== '/404' && !knownPaths.includes(currentPath)) {
      navigate('/404');
    }
  }, [location, navigate]);

  return (
    <div className="App">
      <ScrollToTop />
      <Navbar expand="lg" variant="dark" bg="dark" sticky="top" className="NavBar">
        <Nav className="flex-grow-1">
          <Link to="/" className="hamburger">
           <FontAwesomeIcon color="black" icon={faBars} className="hamburger" />
          </Link> 
        </Nav>
      </Navbar>

      <div className="me-auto">
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/About" element={<About />} />
          <Route exact path="/Info" element={<Info />} />
          <Route exact path="/Memories" element={<ImageGrid />} />
          <Route exact path="/MJ" element={<Resume />} />
          <Route exact path="/download" element={<Dload />} />
          <Route exact path="/Work" element={<Writing />} />
          <Route exact path="/Carbon" element={<Carbon />} />
          <Route exact path="/RMI" element={<RMI />} />
          <Route exact path="/Chainshot" element={<Solidity />} />
          <Route exact path="/Rust" element={<Rust />} />
          <Route path="/pdf/:pdfName" element={<PdfViewer />} />
          <Route path="/404" element={<NotFound />} />
          
        </Routes>
      </div>
    </div>
  );
}

export default App;